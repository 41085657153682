@import "../variable.scss";

.three {
  stroke: $mainBg;
  z-index: -1;
}
.loader-container {
  width: 100%;
  height: 100vh;
}
.loader {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 90%;
    fill: none;
  }
}

.load {
  transform-origin: 50% 50%;
  stroke-dasharray: 570;
  stroke-width: 4px;

  &.one {
    stroke: $freeSpeachRed;
    animation: load 1.5s infinite;
  }

  &.two {
    stroke: $gray;
    animation: load 1.5s infinite;
    animation-delay: 0.1s;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

@keyframes load {
  0% {
    stroke-dashoffset: 570;
  }

  50% {
    stroke-dashoffset: 530;
  }

  100% {
    stroke-dashoffset: 570;
    transform: rotate(360deg);
  }
}
