//FONT FAMILY

$RobotoBold: Roboto-Bold;
$RobotoMedium: Roboto-Medium;
$RobotoRegular: Roboto-Regular;
$RobotoLight: Roboto-Light;
$RobotoMediumItalic: Roboto-MediumItalic;
$RobotoBlack: Roboto-Black;

//COLOR THEME

$gray: #7b7b7b;
$white: #ffffff;
$black: #000000;
$freeSpeachRed: #cb0101;
$mainBg: #f9f9f9;
$darkRed: #970000;
$inputGray: #777373;
$green: #369c09;
$gray100: #262725;
